<template>
  <div>
    <el-dialog :title="title" :visible.sync="isOpenModal" :close-on-click-modal="false" :destroy-on-close="true"
      width="800px" @closed="closeModal" @open="openModal">
      <div class="test">
        <ch-form :render-option="option" :model="modalForm" :rules="rules"
          :props="{ labelWidth: '120px', paddingRight: '20px' }" ref="modalForm">
          <template v-slot:menuType>
            <el-radio-group v-model="modalForm.menuType">
              <el-radio label="M">目录</el-radio>
              <el-radio label="C">菜单</el-radio>
              <el-radio label="F">按钮</el-radio>
            </el-radio-group>
          </template>
          <template v-slot:parentId>
            <el-cascader clearable v-model="modalForm.parent" :options="menuOpt" :props="{ checkStrictly: true }"
              placeholder="请选择上级菜单" size="small" @change="handleChange"></el-cascader>
          </template>
          <template v-slot:icon>
            <ch-upload-img action="/hm/general/upload" :img-length="1" :uploadResponse="handleUpload"
              :removeResponse="handleRemove" :fileList.sync="uploadFileList">
              <div slot="tip">只能上传jpeg/jpg/png文件，且不超过500KB</div>
            </ch-upload-img>
          </template>
          <template v-slot:aicon>
            <ch-upload-img action="/hm/general/upload" :img-length="1" :uploadResponse="handleAiconUpload"
              :removeResponse="handleAiconRemove" :fileList.sync="uploadAiconFileList">
              <div slot="tip">只能上传jpeg/jpg/png文件，且不超过500KB</div>
            </ch-upload-img>
          </template>
        </ch-form>
      </div>
      <span slot="footer" class="flex_con">
        <ch-button @click="closeModal" type="cancel">取消</ch-button>
        <ch-button @click="handleModal('modalForm')" :loading="isButtonLoading" type="ok">确定</ch-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPath } from "./tree";
import { mapActions } from "vuex";
export default {
  props: {
    getListFunction: {
      type: Function,
    },
  },
  data() {
    return {
      title: "",
      editId: "",
      modalForm: {},
      rules: {
        menuName: [
          {
            required: true,
            message: "请输入菜单名称",
            trigger: ["blur", "change"],
          },
        ],
        menuType: [
          {
            required: true,
            message: "请输入菜单类型",
            trigger: ["blur", "change"],
          },
        ],
        path: [
          {
            required: true,
            message: "请输入菜单路由",
            trigger: ["blur", "change"],
          },
        ],
      },
      isButtonLoading: false,
      menuOpt: [],
      allMenuArr: [], // 展开的菜单
      uploadFileList: [], // 上传未激活图标
      uploadAiconFileList: [], // 上传菜单活跃图标
      insertForm_path: "",
      isOpenModal: false,
      type: "",
    };
  },
  computed: {
    option: function () {
      if (this.type === "watch") {
        return [
          { type: "text", label: "菜单名称:", prop: "menuName" },
          { type: "text", label: "菜单类型:", prop: "menu_type" },
          {
            type: "text",
            label: "上级菜单:",
            slotName: "parent_name",
            prop: "parent_name",
          },
          { type: "image", label: "菜单图标:", src: this.modalForm.icon },
          { type: "image", label: "菜单活跃图标:", src: this.modalForm.aicon },
          { type: "text", label: "路由标识:", prop: "path" },
          { type: "text", label: "显示顺序:", prop: "orderNum" },
        ];
      } else {
        return [
          {
            type: "input",
            label: "菜单名称:",
            prop: "menuName",
            placeholder: "请输入菜单名称",
          },
          {
            type: "slot",
            label: "菜单类型:",
            slotName: "menuType",
            prop: "menuType",
          },
          {
            type: "slot",
            label: "上级菜单:",
            slotName: "parentId",
            prop: "parentId",
          },
          { type: "slot", label: "菜单图标:", slotName: "icon", prop: "icon" },
          {
            type: "slot",
            label: "菜单活跃图标:",
            slotName: "aicon",
            prop: "aicon",
          },
          {
            type: "input",
            label: "路由标识:",
            prop: "path",
            placeholder: "请输入菜单路由",
          },
          {
            type: "input",
            label: "显示顺序:",
            prop: "orderNum",
            placeholder: "请输入显示顺序",
          },
        ];
      }
    },
  },
  methods: {
    ...mapActions(["getRouters"]), // 添加权限成功后刷新侧边栏数据
    // 上级菜单
    handleChange(value) {
      this.form_cascader = value;
    },
    // 上传图标
    handleUpload(res) {
      this.modalForm.icon = res.data.url;
    },
    // 上传菜单活跃图标
    handleAiconUpload(res) {
      this.modalForm.aicon = res.data.url;
    },
    handleRemove() {
      this.modalForm.icon = "";
    },
    handleAiconRemove() {
      this.modalForm.aicon = "";
    },
    // 获取上级菜单数据
    getMenuList() {
      this.$curl.post("/hm/menu/list", {}).then((res) => {
        this.menuOpt = this.getMenu(res.data)
        this.allMenuArr = this.getAllMenuArr(res.data)
      });
    },

    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      });
    },
    openWatch(row) {
      this.title = "查看权限管理";
      this.type = "watch";
      this.modalForm = JSON.parse(JSON.stringify(row));
      this.isOpenModal = true;
    },
    openAdd() {
      this.title = "新增菜单管理";
      this.type = "add";
      this.modalForm = {
        menuName: "",
        menuType: "",
        parentId: "",
        parent: "",
        icon: "",
        aicon: "",
        path: "",
        orderNum: "",
      };
      this.getMenuList(); // 获取上级菜单数据
      this.isOpenModal = true;
    },
    openEdit(row) {
      this.title = "编辑菜单管理";
      this.type = "edit";
      this.modalForm = JSON.parse(JSON.stringify(row));
      this.editId = row.id;
      this.modalForm.parent = row.parentId;
      this.modalForm.path = row.path;
      this.modalForm.perms = row.perms;
      this.modalForm.menuType = row.menuType;
      // 图标回显
      let icon = this.modalForm.icon;
      if (icon != "#" && icon != "") {
        this.uploadFileList = [{ name: "未激活图标", url: icon }];
      }
      let aicon = this.modalForm.aicon;
      if (aicon) {
        this.uploadAiconFileList = [{ name: "未激活图标", url: aicon }];
      }
      this.getMenuList(); // 获取上级菜单数据
      this.isOpenModal = true;
    },


    // 执行 增加 或 修改
    handleModal(formRef) {
      let self = this;
      self.$refs[formRef].validate((valid) => {
        if (valid) {
          self.isButtonLoading = true
          if (self.type === "add") {
            let parentId = 0;
            let perms = "";

            if (self.modalForm.parent === "") {
              parentId = 0;
            } else if (self.modalForm.parent.length>0) {
              parentId = self.modalForm.parent[self.modalForm.parent.length-1]
            }
            if (parentId === 0) {
              perms = self.modalForm.path
            } else {
              perms = getPath(this.modalForm.parent, this.allMenuArr) +':'+
                self.modalForm.path
            }
            self.modalForm.perms = perms;
            let params = {
              menuName: self.modalForm.menuName,
              menuType: self.modalForm.menuType,
              parentId: Number(parentId),
              perms: self.modalForm.perms,
              icon: self.modalForm.icon,
              activeIcon: self.modalForm.aicon,
              path: self.modalForm.path,
              orderNum: self.modalForm.orderNum,
            };
            self.$curl.post("/hm/menu/add", params).then(() => {
              self.$message.success("新增成功！");
              self.closeModal();
              self.getListFunction?.();
              self.getRouters();
            }).catch(() => { self.isButtonLoading = false })
          } else {
            let parentId = 0
            if (self.modalForm.parentId) {
              parentId = self.modalForm.parentId;
            } else if (self.modalForm.parent.length>0) {
              parentId = self.modalForm.parent[self.modalForm.parent.length-1]
            }
            let perms = "";
            if (parentId == 0) {
              perms =  self.modalForm.path;
            } else {
              perms = getPath(this.modalForm.parent, this.allMenuArr) +':'+
                  self.modalForm.path
            }
            if (perms) {
              self.modalForm.perms = perms;
            }
            let params = {
              id: self.editId,
              menuName: self.modalForm.menuName,
              menuType: self.modalForm.menuType,
              parentId: self.modalForm.parentId,
              perms: self.modalForm.perms,
              icon: self.modalForm.icon,
              activeIcon: self.modalForm.aicon,
              path: self.modalForm.path,
              orderNum: self.modalForm.orderNum,
            };
            self.$curl.post("/hm/menu/edit", params).then(() => {
              self.$message.success("编辑成功！");
              self.closeModal();
              self.getListFunction?.();
              self.getRouters();
            }).catch(() => self.isButtonLoading = false)
          }
        }
      });
    },
    // 关闭前清除数据
    closeModal() {
      this.isButtonLoading = false
      this.editId = "";
      this.isOpenModal = false;
      this.modalForm = {
        menuName: "",
        menuType: "",
        parentId: "",
        icon: "",
        aicon: "",
        path: "",
        orderNum: "",
      };
      this.uploadFileList = [];
      this.uploadAiconFileList = [];
    },
    // 处理菜单
    getMenu(treeList) {
      return treeList.map(item => {
        if (item.children) {
          item.children = this.getMenu(item.children); // 递归调用处理子节点
        }
        return{
          ...item,
          value:item.id,
          label:item.menuName,
        }
      });
    },
    getAllMenuArr(arr){
      const result = [];
      function flatten(item) {
        result.push({
          ...item
        });
        if (item.children && item.children.length > 0) {
          item.children.forEach(child => flatten(child));
        }
      }
      arr.forEach(flatten);
      return result;
    },
    // getPerms(parent){
    //   // let self = this
    //   let curMenu = this.allMenuArr.filter(item => parent == item.id)
    //   console.log(curMenu,'curMenu')
    //   // parent
    //
    //   // let perms = ''
    //   // curMenu.forEach(item=>{
    //   //   if(item.children){
    //   //
    //   //   }else{
    //   //     perms+=item.perms
    //   //   }
    //   // })
    // },
  },
};
</script>