<template>
  <div>
    <!-- 菜单管理 -->
    <ch-layout button>
      <!-- 按钮 -->
      <template v-slot:button>
        <ch-button
          @click="openAdd"
          >新增</ch-button>
      </template>
      <!-- table -->
      <template v-slot:default>
        <el-table
          size="mini"
          :data="menuData"
          height="100%"
          row-key="id"
          v-loading="isTableLoading"
          :tree-props="{ children: 'children' }">
          <el-table-column
            fixed
            v-for="item of menuColumn"
            :prop="item.props"
            :key="item.id"
            show-overflow-tooltip
            :label="item.label"
            style=""
          ></el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope">
              <ch-button type="link"
                @click="openEdit(scope.row)"
              >编辑</ch-button>
              <ch-button type="link"
                @click="openWatch(scope.row)"
              >查看</ch-button>
              <ch-button type="delete"
                @click="handleDelete(scope.row)"
              >删除</ch-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </ch-layout>
    <!-- 查询 编辑 修改 -->
    <menuModal ref="modal" :getListFunction="getMenuList"></menuModal>
  </div>
</template>
<script>
import { handleDelete } from "@/utils/utils";
// import { concordanceTree, getPathTree } from "./tree";
import menuModal from "./menuModal";
export default {
  components: { menuModal },
  data() {
    return {
      AllTableList: [], // 分页列表
      isTableLoading: false, // table加载
      allMenuArr: [], // 所有菜单数据
      menuData: [], // 所有菜单数据（树结构）
      menuOpt: [], // 表单选择

      watchDialogVisible: false,
      insertDialogVisible: false,
      editDialogVisible: false,

      menuColumn: [
        { id: 1, label: "名称", props: "menuName" },
        { id: 2, label: "菜单ID", props: "id" },
        { id: 3, label: "路径", props: "path" },
        { id: 4, label: "显示顺序", props: "orderNum" },
        { id: 5, label: "类型", props: "menu_type" },
      ],

    };
  },
  computed: {},
  created() {
  },
  mounted() {
    this.getMenuList();
  },
  methods: {
    // 列表数据
    getMenuList() {
      this.isTableLoading = true;
      this.menuData = []
      this.$curl
        .post("/hm/menu/list", {})
        .then((res) => {
          this.menuData= this.getTree(res.data)
          this.isTableLoading = false;
          // let NotButtonArr = res.rows.filter((item) => item.menuType !== "F");
          // if (NotButtonArr.length) {
          //   let menuData = concordanceTree(NotButtonArr);
          //   this.menuOpt = getPathTree(menuData);
          // }
          // this.allMenuArr = res.rows;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    getTree(treeList) {
      return treeList.map(item => {
        if (item.children) {
          item.children = this.getTree(item.children); // 递归调用处理子节点
        }
        return {
          ...item,
          menu_type: item.menuType === 'M' ? '目录' : (item.menuType === 'C' ? '菜单': '按钮'),
        }
      });
    },
    // 打开查看
    openWatch(row) {
      this.$refs.modal?.openWatch(row);
    },
    // 打开增加
    openAdd() {
      this.$refs.modal?.openAdd();
    },
    // 打开修改
    openEdit(row) {
      this.$refs.modal?.openEdit(row);
    },
    // 删除
    handleDelete(row) {
      handleDelete("/hm/menu/delete/", row.id).then(() => {
        this.getMenuList();
      });
    },
  },
};
</script>

<style lang="scss">

</style>
