//获取上级path，并用":"拼接
export function getPath(pathArr,allMenu){
   let last_one = typeof pathArr == 'number' ? pathArr : pathArr[pathArr.length - 1]

   let arr = []
   if(typeof pathArr !== 'number'){
      for(let l = 0; l < pathArr.length; l ++){
         if(last_one == pathArr[l]){
            let menuPath = allMenu.find(item => item.id == pathArr[l])
            arr.push(menuPath.path.trim())
         }else{
            let menuPath = allMenu.find(item => item.id == pathArr[l])
            arr.push(menuPath.path.trim())
         }
      }
   }else if(typeof pathArr == 'number'){
      let menuPath = allMenu.find(item => item.id == pathArr)
      arr.push(menuPath.path.trim())
      if(menuPath.parentId > 0){
         getParentPath(arr,menuPath.parentId,allMenu)
      }
      arr.reverse()
   }

   return arr.join(':')
}

function getParentPath(arr,pathArr,allMenu){
   let menuPath = allMenu.find(item => item.id == pathArr)
   arr.push(menuPath.path.trim())
   if(menuPath.parentId > 0){
      getParentPath(arr,menuPath.parentId,allMenu)
   }
}